import { database } from '@/js/store/services/firebase.service'
import color from 'color'
import cssx from 'cssx'
import * as _ from 'lodash'
import * as webfont from 'webfontloader'
import { DEFAULT_COLOR, rgbaToColor } from '../../lib/color-utils'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

import {
  ButtonFormat,
  FONT_SIZES,
  FroalaFormat,
  FroalaLinkFormat,
  GOOGLE_FONTS, DEFAULT_TYPOGRAPHY, DEFAULT_BUTTON_TYPOGRAPHY, DEFAULT_FROALA_LINK_TYPOGRAPHY
} from "../../lib/font";

let db = database()

let typography = cssx('cssx-typography-styles')
let themeColors = cssx('cssx-theme-colors')
let froalaLinkDefaultTypography = cssx('cssx-froala-link-default-typography')
let mobileViewTypography = cssx('cssx-mobile-view-typography-styles')

cssx.minify(false)
typography.scope('.typography')
mobileViewTypography.scope('.page-engine-viewmode--sm')

function pxToRem({ px, base = 16, factor }) {
  let rem = (px / base) * factor
  return `${rem < 1 ? 1 : rem}rem`
}
// Disable blockquote left side base
typography.add({'.fr-view blockquote': {'border-left': 'none !important'}})

function updateTypography(typo) {
  typography.add(
    _.chain(typo)
      .mapKeys((v, k) => `.font-style-${k}`)
      .mapValues((f) => f.toStyle())
      .value(),
  )

  typography.add(
    _.chain(typo)
      .mapKeys((v, k) => `.alt-text-color .font-style-${k}`)
      .mapValues((f) => f.getDefaultAltTextColor())
      .value(),
  )

  typography.add({
    '@media (max-width: 768px)': _.chain(typo)
      .mapKeys((v, k) => `.font-style-${k}`)
      .mapValues((f) => ({
        'font-size': pxToRem({ px: f.size, factor: 0.9 }),
      }))
      .value(),
  })

  typography.add({
    '@media (max-width: 575px)': _.chain(typo)
      .mapKeys((v, k) => `.font-style-${k}`)
      .mapValues((f) => ({
        'font-size': pxToRem({ px: f.size, factor: 0.8 }),
      }))
      .value(),
  })
  mobileViewTypography.add(
      _.chain(typo)
          .mapKeys((v, k) => `.font-style-${k}`)
          .mapValues((f) => ({
            'font-size': pxToRem({ px: f.size, factor: 0.8 }),
          }))
          .value(),
  )
}

function updateFrolaLinkTypography(froalaLinkTypo) {
  froalaLinkDefaultTypography.add({
    '.draft-editor .fr-element a': {
      color: froalaLinkTypo.link.defaultTextColor,
    },
    '.draft-editor .fr-element a:hover': {
      color: froalaLinkTypo.linkHover.defaultTextColor,
    },
    '.draft-editor .fr-element a:visited': {
      color: froalaLinkTypo.linkVisited.defaultTextColor,
    },
  })
}

function addFontClassesToSheet(fonts) {
  typography.add(
    _.zipObject(
      fonts.map((f) => f.froalaClassName()),
      fonts.map((f) => f.toStyle()),
    ),
  )
}

function addFontSizesToSheet(sizes) {
  typography.add(
    _.zipObject(
      sizes.map((s) => `.font-size-${s.value}`),
      sizes.map((s) => ({
        'font-size': `${s.value}px`,
      })),
    ),
  )

  typography.add({
    '@media (max-width: 768px)': _.zipObject(
      sizes.map((s) => `.font-size-${s.value}`),
      sizes.map((s) => ({
        'font-size': pxToRem({ px: s.value, factor: 0.9 }),
      })),
    ),
    '@media (max-width: 575px)': _.zipObject(
      sizes.map((s) => `.font-size-${s.value}`),
      sizes.map((s) => ({
        'font-size': pxToRem({ px: s.value, factor: 0.8 }),
      })),
    ),
  })
  mobileViewTypography.add(
      _.zipObject(
          sizes.map((s) => `.font-size-${s.value}`),
          sizes.map((s) => ({
            'font-size': pxToRem({ px: s.value, factor: 0.8 }),
          })),
      )
  )
}

function updateThemeColors(colors) {
  for (let id in colors) {
    let color = colors[id]
    themeColors.add({
      [`.font-color-${id}`]: {
        color: color.hex(),
      },
    })
  }
}

webfont.load({
  google: {
    families: GOOGLE_FONTS.filter((f) => f.source === 'google').map((f) => f.webfontName()),
  },
})


const DEFAULT_PALETTE_COLORS = [
  {
    a: 1,
    hex: '#e64a19',
  },
  {
    a: 1,
    hex: '#ffa000',
  },
  {
    a: 1,
    hex: '#43a047',
  },
  {
    a: 1,
    hex: '#384cad',
  },
  {
    a: 1,
    hex: '#ffffff',
  },
  {
    a: 1,
    hex: '#212121',
  },
]

export default {
  state: {
    colors: {},
    blockColors: {},
    typography: DEFAULT_TYPOGRAPHY,
    buttonTypography: DEFAULT_BUTTON_TYPOGRAPHY,
    froalaLinkTypography: DEFAULT_FROALA_LINK_TYPOGRAPHY,
    availableFonts: [...GOOGLE_FONTS],
    defaultColor: DEFAULT_COLOR,
    themeRef: null,
    brandLogo: null,
  },
  getters: {
    [getters.THEME]({ colors, typography, blockColors, buttonTypography }) {
      return {
        colors,
        blockColors,
        buttonTypography,
        typography,
      }
    },
    [getters.THEME_TYPOGRAPHY]({ typography }) {
      return typography
    },
    [getters.THEME_GET_COLORS]({ colors }) {
      return colors
    },
    [getters.THEME_GET_BLOCK_COLORS]({ blockColors }) {
      return blockColors
    },
    [getters.THEME_DEFAULT_COLOR]({ defaultColor }) {
      return defaultColor
    },
    [getters.THEME_AVAILABLE_FONTS]({ availableFonts }) {
      return availableFonts
    },
    [getters.THEME_FONT_STYLES]({ typography }) {
      return [
        { style: 'title', label: 'Title', tag: 'h1' },
        { style: 'subtitle', label: 'Subtitle', tag: 'h2' },
        { style: 'heading', label: 'Heading', tag: 'h3' },
        { style: 'alternativeHeading', label: 'Alternative Heading', tag: 'h4' },
        { style: 'lead', label: 'Lead', tag: 'h5' },
        { style: 'normalText', label: 'Normal Text', tag: 'p' },
        { style: 'blockquote', label: 'Blockquote', tag: 'blockquote' },
      ].map((s) => {
        return {
          ...s,
          format: typography[s.style],
        }
      })
    },
    [getters.THEME_BUTTON_DEFAULT_STYLES]({ buttonTypography }) {
      return [{ item: 'button', label: 'Button' }].map((s) => {
        return {
          ...s,
          format: buttonTypography[s.item],
        }
      })
    },
    [getters.THEME_FROALA_LINK_DEFAULT_STYLES]({ froalaLinkTypography }) {
      return [
        { item: 'link', label: 'Link' },
        { item: 'linkHover', label: 'Hover Link' },
        { item: 'linkVisited', label: 'Visited Link' },
      ].map((s) => {
        return {
          ...s,
          format: froalaLinkTypography[s.item],
        }
      })
    },
    [getters.THEME_BRAND_LOGO]({ brandLogo }) {
      return brandLogo
    },
  },
  mutations: {
    [mutations.THEME_UPDATE_DATA](state, theme) {
      state.colors = theme.colors
        ? _.mapValues(theme.colors, (c) => {
            return c.rgba ? rgbaToColor(c) : color(c.hex)
          })
        : {}
      state.blockColors = theme.blockColors
        ? _.mapValues(theme.blockColors, (c) => {
            return color(c.hex)
          })
        : {}


      state.typography =
        theme.typography &&
        theme.typography.title &&
        theme.typography.title.defaultTextColor &&
        theme.typography.title.defaultAltTextColor
          ? _.mapValues(theme.typography, (v, k) => FroalaFormat.fromStyle(k, v))
          : DEFAULT_TYPOGRAPHY

      state.buttonTypography = 
        (theme.buttonTypography &&
        theme.buttonTypography.button &&
        theme.buttonTypography.button.fontWeight &&
        theme.buttonTypography.button.subtextStyle)
        ? _.mapValues(theme.buttonTypography, (v) => new ButtonFormat(v))
        : DEFAULT_BUTTON_TYPOGRAPHY

      state.froalaLinkTypography =
        theme.froalaLinkTypography &&
        theme.froalaLinkTypography.link &&
        theme.froalaLinkTypography.link.defaultTextColor
          ? _.mapValues(
              theme.froalaLinkTypography,
              (v) => new FroalaLinkFormat(v),
            )
          : DEFAULT_FROALA_LINK_TYPOGRAPHY

      state.brandLogo = theme.brandLogo

      updateTypography(state.typography)
      updateFrolaLinkTypography(state.froalaLinkTypography)

      updateThemeColors(state.colors)
      updateThemeColors(state.blockColors)

      addFontClassesToSheet(GOOGLE_FONTS)
      addFontSizesToSheet(FONT_SIZES)
    },
    [mutations.THEME_SET_FONT](state, { style, format }) {
      state.typography[style] = FroalaFormat.fromStyle(style, format)
      updateTypography(state.typography)
    },
    [mutations.THEME_SET_BUTTON_DEFAULT_STYLES](state, { item, format }) {
      state.buttonTypography[item] = new ButtonFormat(format)
    },

    [mutations.THEME_SET_FROALA_LINK_DEFAULT_STYLES](state, { item, format }) {
      state.froalaLinkTypography[item] = new FroalaLinkFormat(format)
      updateFrolaLinkTypography(state.froalaLinkTypography)
    },
  },
  actions: {
    [actions.THEME_INIT]({ state, commit, dispatch }, fbSitePath) {
      if (state.themeRef) {
        state.themeRef.off('value')
      }

      state.themeRef = db.ref(fbSitePath).child('theme')

      state.themeRef.on('value', (snap) => {
        let theme = snap.val()

        // old typography data structure needs to be nulled out
        if (
          theme &&
          theme.typography &&
          Object.keys(theme.typography).includes('h1')
        ) {
          theme.typography = null
        }

        if (theme) {
          if (!theme.colors) {
            for (let defaultColor of DEFAULT_PALETTE_COLORS) {
              let colorVal = color(defaultColor.hex)
              dispatch(actions.THEME_ADD_NEW_COLOR, colorVal)
            }
          }
          commit(mutations.THEME_UPDATE_DATA, theme)
        }

        if (!theme || !theme.typography) {
          state.themeRef.child('typography').set(state.typography)
        }

        // update newly added typography
        let typographyKeys = Object.keys(DEFAULT_TYPOGRAPHY)
        let newKeysExist = (typographyKeys.some(key => !state.typography[key]))

        if(newKeysExist) {
          for(let key of typographyKeys) {
            if(!state.typography[key]) {
              state.typography[key] = DEFAULT_TYPOGRAPHY[key]
            }
          }
          state.themeRef.child('typography').set(state.typography)
        }

        if (!theme || !theme.buttonTypography || !theme.buttonTypography.fontWeight || !theme.buttonTypography.subtextStyle) {
          state.themeRef.child('buttonTypography').set(state.buttonTypography)
        }

        if (!theme || !theme.froalaLinkTypography) {
          state.themeRef
            .child('froalaLinkTypography')
            .set(state.froalaLinkTypography)
        }
      })
    },
    [actions.THEME_UPDATE_COLOR]({ state }, { color, key }) {
      let colorRef = state.themeRef.child('colors').child(key)

      return colorRef
        .set({
          a: color.alpha(),
          hex: color.hex().toLowerCase(),
        })
        .then(() => {
          state.colors[key] = color
          updateThemeColors(state.colors)
          return colorRef.key
        })
    },
    [actions.THEME_ADD_NEW_COLOR]({ state }, color) {
      let colorRef = state.themeRef.child('colors').push()

      return colorRef
        .set({
          a: color.alpha(),
          hex: color.hex().toLowerCase(),
        })
        .then(() => {
          state.colors[colorRef.key] = color
          return colorRef.key
        })
    },
    [actions.THEME_ADD_NEW_COLORS]({ state }, paletteColors) {

      const colorsRef = state.themeRef.child('colors')
      return colorsRef.once('value', (snapshot) => {
          return _.mapValues(paletteColors, (colorObj, colorKey) => {
            
            if(!snapshot.hasChild(colorKey)) {
              const colorPluginObj = color(colorObj.hex).alpha(colorObj.a || colorObj.alpha || 1)

              state.colors[colorKey] = colorPluginObj

              return colorsRef.child(colorKey)
              .set({
                a: colorPluginObj.alpha(),
                hex: colorPluginObj.hex(),
              })
            }
          });
      });
    },
    [actions.THEME_ADD_COLORS_FROM_BLOCK]({ state }, colors) {
      let chain = _.chain(colors)
        .keysIn()
        .map((colorId) =>
          state.themeRef
            .child('blockColors')
            .child(colorId)
            .set(colors[colorId]),
        )

      return Promise.all(chain).then(() => {
        state.blockColors = {
          ...state.blockColors,
          ..._.mapValues(colors, (colorData) => color(colorData.hex)),
        }
      })
    },
    [actions.THEME_SET_FONT]({ state, commit }, { style, format }) {
      commit(mutations.THEME_SET_FONT, { style, format })
      return state.themeRef.child('typography').set(state.typography)
    },
    [actions.THEME_SET_BRAND_LOGO]({ state }, logo) {
      state.brandLogo = logo
      return state.themeRef.child('brandLogo').set(logo)
    },
    [actions.THEME_REMOVE_COLOR]({ state }, color) {
      return state.themeRef.child('colors').child(color).remove()
    },
    [actions.THEME_SET_BUTTON_DEFAULT_STYLES]({ state, commit }, { item, format }) {
      commit(mutations.THEME_SET_BUTTON_DEFAULT_STYLES, { item, format })
      return state.themeRef
        .child('buttonTypography')
        .set(state.buttonTypography)
    },
    [actions.THEME_SET_FROALA_LINK_DEFAULT_STYLES](
      { state, commit },
      { item, format },
    ) {
      commit(mutations.THEME_SET_FROALA_LINK_DEFAULT_STYLES, { item, format })
      return state.themeRef
        .child('froalaLinkTypography')
        .set(state.froalaLinkTypography)
    },
  },
}
