
<div class="container py-5" :style="titleStyle">
  <div class="row mb-2">
    <div class="col-md-12 text-center">
      <h2 class="setup-title" :style="titleStyle">
        {{
          sitePublished
            ? $t('launch.message.title')
            : $t('launch.message.title_alt')
        }}
      </h2>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-md-10 mx-auto">
      <p class="text-center publish-subtitle">
        {{
          sitePublished
            ? $t('launch.message.sub_title')
            : $t('launch.message.sub_title_alt')
        }}
      </p>
      <p v-if="config.WORDPRESS_PLUGIN_ENABLED" class="text-center">
        {{ $t('launch.message.wordpress.text') }}
        <i
          ><a
            target="_blank"
            href="https://wordpress.org/plugins/infusionsoft-landing-pages/"
            >{{ $t('launch.message.wordpress.link') }}</a
          ></i
        >
      </p>
    </div>
  </div>

  <cvt-popover placement="bottom" target="share" triggers="click">
    <template #popper>
      <social-share-buttons :url="site.siteUrl"></social-share-buttons>
    </template>
  </cvt-popover>

  <cvt-publish-card
    :loading="loading"
    :published="sitePublished"
    :disabled="setPublishButtonDisabled"
    :disabled-custom-message="disabledCustomMessage"
    :custom-disabled-message-enabled="customDisabledMessageEnabled"
    :thumbnail="
      realtimeThumbnail === 'loading' || realtimeThumbnail === ''
        ? ''
        : realtimeThumbnail
    "
    :special-style="publishBtnStyle"
    @publish="publishSite(site, $event)"
    @republish="publishSite(site, $event)"
  />

  <fade-in-out>
    <div class="row mb-5 mt-4 d-flex justify-content-center">
      <div
        v-if="showLandingPageUrl"
        :key="showLandingPageUrl"
        class="col-md-6 mt-2 text-left"
      >
        <div class="row">
          <div class="col">
            <div class="input-group">
              <cvt-input
                id="site_url"
                class="input-group__url"
                type="text"
                :label="$t('launch.message.url')"
                :value="site.siteUrl"
                :readonly="true"
              />
              <cvt-button
                text="Copy"
                class="input-group__copy"
                color="light"
                :btn-text-color="publishPageConfig.copyButtonColor || ''"
                :outlined="true"
                @click="copySiteUrl"
              />
            </div>
            <div
              v-if="!loading && sitePublished"
              class="justify-content-center input-group"
            >
              <a
                :href="site.siteUrl"
                class="landing__page--link"
                target="_blank"
              >
                <cvt-button color="light" text="Open your landing page" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fade-in-out>

  <div class="row mb-5 justify-content-center">
    <div class="col-6">
      <cvt-tabs
        :keep-inactive-border="
          publishPageConfig.keepInactiveBorder
            ? publishPageConfig.keepInactiveBorder
            : false
        "
        :full-horizontal-border="
          publishPageConfig.fullHorizontalBorder
            ? publishPageConfig.fullHorizontalBorder
            : false
        "
        :smaller-tab-border="
          publishPageConfig.smallerTabBorder
            ? publishPageConfig.smallerTabBorder
            : false
        "
        :in-active-border-color="
          publishPageConfig.inActiveBorderColor
            ? publishPageConfig.inActiveBorderColor
            : ''
        "
        :closeable="false"
        prefix-icon=""
      >
        <cvt-tab
          class="custom_domain--tab"
          :title="$t('launch.custom_domains.tab_title')"
          name="custom_domains"
        >
          <cvt-custom-domain-action
            :key="linkBtnLoading"
            :domain="domain"
            :show-domain-instruction="showDomainInstruction"
            :link-btn-text="linkBtnText"
            :link-btn-loading="linkBtnLoading"
            :domain-info="domainInfo"
            :linked-domains="linkedDomains"
            :site="site"
            :theme="connectBtnColor"
            :outlined="connectBtnOutlined"
            :unlink-btn-theme="unlinkBtnTheme"
            @refresh="loadDomains"
            @validateDomainInput="validateDomainInput"
            @domain-input="updateDomain"
            @dns-unlink="
              (siteId, domain) => openUnlinkDialog(siteId, domain.toString())
            "
            @link="linkDomain"
            @clear="clearDomain"
          />
          <cvt-alert
            v-if="linkSuccessMessage.length > 0"
            color="success"
            icon="check"
          >
            {{ linkSuccessMessage }}
          </cvt-alert>
          <cvt-alert
            v-if="linkErrorMessage.length > 0"
            color="danger"
            icon="exclamation-triangle"
          >
            {{ linkErrorMessage }}
          </cvt-alert>
        </cvt-tab>
        <cvt-tab :title="$t('launch.embed.tab_title')" name="embed">
          <h5 class="mt-2 mb-4">
            {{ $t('launch.message.embed_code') }} (Iframe)
          </h5>
          <cvt-alert color="info" icon="info">
            <p>{{ $t('launch.embed.info.message') }}</p>
          </cvt-alert>
          <textarea
            :class="getClassForTextArea"
            :value="site.embedCode"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            style="resize: none"
            @click="copyToClipboard"
          >
          </textarea>
        </cvt-tab>
        <cvt-tab v-if="isAdmin" title="Admin">
          <div class="row mt-5 d-flex justify-content-space-evenly">
            <div class="col-6 pb-4 d-flex justify-content-start">
              <categories></categories>
            </div>
            <div class="col-6">
              <enable-marketplace
                :marketplace-enabled="siteMarketPlaceEnabled"
                @switchToggle="toggleMarketplaceFlag"
              />
            </div>
            <div
              v-if="demoTemplateSwitchEnabled && !isStandalonePopupActive"
              class="col-6 pb-4 d-flex justify-content-start"
            >
              <enable-demo-template
                :marketplace-enabled="siteDemoTemplateEnabled"
                @switchToggle="toggleDemoTemplateFlag"
              />
            </div>
            <template
              v-if="!isStandalonePopupActive && isGenAIFeatureEnabled"
            >
              <div class="col-6 pb-4 d-flex justify-content-start">
                <div data-identity="EnableGenAIToggle">
                  <h5 class="admin-label">
                    {{ $t('launch.tab.control.heading.enable_gen_ai') }}
                  </h5>
                  <cvt-switch
                    size="sm"
                    color="active"
                    type="circular"
                    description-type="default"
                    :mode="mode"
                    :initial-state="isGenAiEnabled"
                    @switchToggle="(status) => toggleGenAiEnabled(status)"
                  />
                </div>
              </div>

              <div class="col-12 pb-4 d-flex justify-content-start">
                <cvt-select
                  :key="selectedIntents?.length"
                  class="w-100"
                  mode="dropdown"
                  :label="
                    $t('launch.tab.control.heading.intent_of_landing_page')
                  "
                  :background-mode="mode"
                  :multiple="true"
                  :value="selectedIntents"
                  @input="(value) => setGenAISelectedIntents(value)"
                >
                  <option
                    v-for="intentObj in allIntents"
                    :key="intentObj.value"
                    :value="intentObj.value"
                    v-text="intentObj.label"
                  ></option>
                </cvt-select>
              </div>

              <div class="col-12 pb-4 d-flex justify-content-start">
                <cvt-select
                  :key="selectedIntents?.length"
                  class="w-100"
                  mode="dropdown"
                  :label="
                    $t('launch.tab.control.heading.specific_intent_goal')
                  "
                  :background-mode="mode"
                  :multiple="true"
                  :value="selectedIntentGoals"
                  @input="(value) => setGenAISelectedIntentGoals(value)"
                >
                  <optgroup
                    v-for="intentObj of allIntents"
                    :key="intentObj.value"
                    :label="intentObj.label"
                  >
                    <option
                      v-for="intentGoal in intentObj.goals"
                      :key="intentGoal.value"
                      :value="intentGoal.value"
                      v-text="intentGoal.label"
                    ></option>
                  </optgroup>
                </cvt-select>
              </div>
            </template>

            <template v-if="templatePreviewAlert && !isStandalonePopupActive">
              <div class="col-6 pb-4 d-flex justify-content-start">
                <add-image-url
                  :image-url="siteImageUrl"
                  :label="$t('launch.tab.control.heading.add_image_url')"
                  @siteImageUrlChange="siteImageUrlChange"
                />
              </div>
              <div class="col-6 pb-4 d-flex justify-content-start">
                <add-verified-seal
                  :verified-seal-enabled="siteVerifiedSealEnabled"
                  @switchToggle="toggleVerifiedSealFlag"
                />
              </div>
              <div class="col-12 pb-4">
                <add-video-url
                  :video-url="siteVideoUrl"
                  @siteVideoUrlChange="siteVideoUrlChange"
                />
              </div>
              <div class="col-12 w-100 pb-4">
                <add-description
                  :description="siteDescription"
                  @siteDescriptionChange="siteDescriptionChange"
                />
              </div>
            </template>
          </div>
          <div class="row mt-1 d-flex justify-content-space-evenly">
            <div class="col-12 w-100 pb-1">
              <cvt-button
                :mode="mode"
                :text="'Submit'"
                :color="''"
                :loading="submittingAdminData"
                :shadow="true"
                :disabled="false"
                @click="(event) => submitAdminTabSiteData(event)"
              />
            </div>
          </div>
        </cvt-tab>
      </cvt-tabs>
    </div>
  </div>

  <cvt-dialog
    :show="unlinkDialog"
    size="sm"
    :height-auto="true"
    :scroll-on-overflow="true"
    @close="unlinkDialog = false"
  >
    <template #title>
      <h5>
        {{ `${$t('custom_domain.ui.unlink_dialog.h5')} ${unlinkDomainName}` }}
      </h5>
    </template>
    <cvt-alert color="warning" icon="exclamation-triangle">
      <p v-if="unlinkExistingDomain">
        {{
          `${$t(
            'custom_domain.ui.unlink_dialog.p.unlink_existing_domain',
          )} ${unlinkDomainName} ${$t(
            'custom_domain.ui.unlink_dialog.p.part_two',
          )}`
        }}
      </p>
      <p v-else>
        {{
          `${$t(
            'custom_domain.ui.unlink_dialog.p.part_one',
          )} ${unlinkDomainName} ${$t(
            'custom_domain.ui.unlink_dialog.p.part_two',
          )}`
        }}
      </p>
    </cvt-alert>

    <template #modalFooter>
      <cvt-button
        color="light"
        :text="$t('custom_domain.ui.unlink_dialog.buttons.cancel')"
        @click="unlinkDialog = false"
      />
      <cvt-button
        :color="popUpBtnColor"
        :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
        @click="unlinkDomain(unlinkDomainName)"
      />
    </template>
  </cvt-dialog>

  <cvt-dialog
    :show="apexDialog"
    size="lg"
    :height-auto="true"
    :scroll-on-overflow="true"
    @close="apexDialog = false"
  >
    <template #title>
      <h5>{{ $t('custom_domain.ui.apex_popup.title') }}</h5>
    </template>
    <cvt-alert color="warning" icon="exclamation-triangle">
      <h2>{{ $t('custom_domain.ui.apex_popup.h2.first') }}</h2>
    </cvt-alert>

    <p>
      {{ $t('custom_domain.ui.apex_popup.p1.part_one')
      }}<code>example.com</code>
      {{ $t('custom_domain.ui.apex_popup.p1.part_two')
      }}<code>www.example.com</code>
      {{ $t('custom_domain.ui.apex_popup.p1.part_three')
      }}<code>example.com</code>
      {{ $t('custom_domain.ui.apex_popup.p1.part_four')
      }}<code>www.example.com</code>)
    </p>

    <h2 class="mt-5">{{ $t('custom_domain.ui.apex_popup.h2.second') }}</h2>

    <p>
      {{ $t('custom_domain.ui.apex_popup.p2.part_one') }}
      <a href="https://www.ietf.org/rfc/rfc1034.txt">RFC1034</a>
      {{ $t('custom_domain.ui.apex_popup.p2.part_two') }}
    </p>

    <p>
      {{ $t('custom_domain.ui.apex_popup.p3.part_one') }}<code>www</code
      >{{ $t('custom_domain.ui.apex_popup.p3.part_two') }} (<code
        >example.com</code
      >{{ $t('custom_domain.ui.apex_popup.p3.part_three')
      }}<code>www.example.com</code>).
    </p>
  </cvt-dialog>
</div>
