
<div
  :id="vnodeId || formVNodeId"
  :data-vnode-id="vnodeId || formVNodeId"
  class="cvt-editor-button"
  :class="containerClasses"
  :draggable="!isGridBuilder ? 'true' : 'false'"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @dblclick.stop.prevent
  @dragstart="dragstart"
  @dragend="dragend"
>
  <template v-if="!form">
    <drag-and-drop-zone
      :z-index="dropzoneZIndex"
      @dropped="addElementToSide"
    />

    <a
      :href="btnURL"
      class="btn"
      :class="btnClasses"
      :target="gumroadEnabled ? '_blank' : anchorTarget"
      role="button"
      :data-popup="'grid-stack-' + popupId"
      :style="btnStyle"
    >
      <span :style="innerBtnStyle">
        <span v-if="iconEnabled && iconPosition === 'left'"><icon-template :set="set" :selector="selector" /></span>
        <span>{{ cta }}</span>
        <span v-if="iconEnabled && iconPosition === 'right'"><icon-template :set="set" :selector="selector" /></span>
        </span>
      <span :style="subtextStyle">{{ subtext }}</span>
    </a>
  </template>

  <template v-if="form">
    <button class="btn" :class="btnClasses">
      <span :style="innerBtnStyle">
        <span v-if="iconEnabled && iconPosition === 'left'"><icon-template :set="set" :selector="selector" /></span>
        <span>{{ cta || 'Call To Action' }}</span>
        <span v-if="iconEnabled && iconPosition === 'right'"><icon-template :set="set" :selector="selector" /></span>
      </span>
    </button>
  </template>

  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
      v-if="selected"
      @duplicate="duplicateAndValidate"
      @remove="removeAndValidate"
    />
  </template>
  <span :style="subtextStyle">{{ helper_text }}</span>
</div>
