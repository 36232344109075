<template>
  <div
    class="
      cvt-editor-grid-item-wrapper
      grid-stack-item-content
      grid-stack-draggable-item grid-stack-item
      ui-draggable
      item-hover-border
    "
    :class="{ 'pointer-events-none': isRegenerating }"
    data-identity="GridItemWrapper"
    :data-gs-x="dataX"
    :data-gs-y="dataY"
    :data-gs-width="dataWidth"
    :data-gs-height="dataHeight"
    :data-vnode-id="vnodeId"
    :gs-x="dataX"
    :gs-y="dataY"
    :gs-w="dataWidth"
    :gs-h="dataHeight"
    :gs-locked="false"
    :gs-auto-position="false"
    :gs-no-resize="noResize"
    :data-gs-locked="false"
    :data-is-child-trageted="isChildTrageted"
    :data-is-child-selected="isChildSelected"
    :data-child-name="childComponent.name"
    @dblclick.stop=""
    @mouseenter="gridItemWrapperMouseEnter"
    @mouseover="targetChildElement"
  >
      <div
        class="grid-stack-item-content grid-item-wrapper-content-wrapper"
        @dblclick="toggleOverlay(false)"
      >
        <div
          v-if="isNonDraggableContent"
          class="editable-element-overlay"
          :class="contentWrapClasses"
        >
          <cvt-button
            class="editable-element-edit-button"
            color="light"
            size="md"
            :mode="'LIGHT'"
            :text="editBtnText"
            :icon="'pencil'"
            :outlined="true"
            @click="toggleOverlay(false)"
          />
        </div>

        <div class="grid-item-actions">
          <div class="ui-draggable-handle move-icon">
            <cvt-icon icon="arrows-alt"/>
          </div>
        </div>

        <div
          ref="content"
          class="d-flex w-100 grid-stack-item-element-content"
          :class="contentClasses"
          data-identity="GridItemWrapperContent"
        >
          <template v-for="node in fbNode.children">
            <template v-if="node.tag === 'sub-grid-container'">
              <sub-grid-container-v3 v-bind="node.data.props" :key="node.vnodeId" :fb-node="node"/>
            </template>
            <template v-else-if="node.children !== undefined">
              <component :is="node.tag" v-bind="node.data.props" :key="node.vnodeId" :fb-node="node">
                <template v-for="item in node.children">
                  <component :is="item.tag" v-bind="item.data.props" :key="item.vnodeId" :fb-node="node"></component>
                </template>
              </component>
            </template>
            <template v-else>
              <component :is="node.tag" v-bind="node.data.props" :key="node.vnodeId" :fb-node="node"></component>
            </template>
          </template>
        </div>
      </div>
      <action-buttons
        v-if="selectedChild && !isBuilderOnStaticMode"
        :show-regenerate="showRegenerateBtn"
        @duplicate="duplicateElement"
        @remove="removeElement"
        @regenerate="regenerateElement"
      />

      <template v-if="fbNode.children.length === 0 && !isBuilderOnStaticMode">
        <action-buttons :show-duplicate="false" @remove="forceRemoveElement" />
      </template>

  </div>
</template>

<script>
import FirebaseNodeV3 from '../../base/FirebaseNode'
import { mapGetters } from 'vuex'
import * as getters from '../../../../store/modules/getters'
import GridItemWrapper from "../../../editor/nodes/GridItemWrapper.vue";
import Icon from "./Icon.vue";
import CButton from "./CButton.vue";
import DraftFroala from "./DraftFroala.vue";
import CImage from "./CImage.vue";
import CSurvey from "./CSurvey.vue";
import Divider from "./Divider.vue";
import CVideo from "./CVideo.vue";
import CForm from "./CForm.vue";
import BrandLogo from "./BrandLogo.vue";
import BuyNowButton from "./BuyNowButton.vue";
import CPopupBuilder from "./CPopupBuilder.vue";
import CodeSnippet from "./CodeSnippet.vue";
import Checkout from "./Checkout/Checkout.vue";
import SubGridContainer from "./SubGridContainer.vue";
import CountdownTimer from "./CountdownTimer/CountdownTimer.vue";
import ActionButtons from "./ActionButtons.vue";
import { V1Sites } from "@/js/store/services/api";

const sites = new V1Sites()

const components = {
  'icon': Icon,
  'c-button':  CButton,
  'draft':  DraftFroala,
  'c-image':  CImage,
  'c-survey':  CSurvey,
  'divider':  Divider,
  'c-video':  CVideo,
  'c-form':  CForm,
  'brand-logo':  BrandLogo,
  'buy-now-button':  BuyNowButton,
  'c-popup-builder':  CPopupBuilder,
  'code-snippet':  CodeSnippet,
  'Checkout':  Checkout,
  'sub-grid-container-v3': SubGridContainer,
  'countdown-timer': CountdownTimer,
  'action-buttons': ActionButtons
}

export default GridItemWrapper.extend({
  name: 'GridItemWrapperV3',
  mixins: [FirebaseNodeV3],
  components: components,
  data() {
    return {
      isRegenerating: false
    }
  },
  computed: {
    ...mapGetters({
      selectedComponent: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      site: getters.SITE_GET_DATA
    }),
    selectedChild() {
      if (this.fbNode.children.length === 1) {
        return this.selectedComponent === this.$children[1]
      }
      return false
    },
    noResize () {
      return this[this.viewMode]?.noResize || false
    },
    isBuilderOnStaticMode () {
      return this.config.GRID_BUILDER_STATIC_MODE_ENABLED || false
    },
    showRegenerateBtn() {
      const includedComponents = ['CButton', 'Draft']
      return includedComponents.includes(this.selectedComponent.name) && Boolean(this.site.intent?.length)
      // return includedComponents.includes(this.selectedComponent.name) && Boolean(this.site.intent?.length) && (this.config.SHOW_REGENERATE_CONTENT_BTN || false)
    }
  },
  methods: {
    duplicateElement () {
      if (this.fbNode.children.length === 1) {
        this.$emit('duplicateElement', this.fbNode)
      }
    },
    removeElement () {
      this.$emit('removeElement', this)
    },
    forceRemoveElement () {
      this.$emit('forceRemoveElement', this)
    },
    getChildComponentTag (val) {
      if (this.fbNode?.children?.length === 1) {
        return this.fbNode.children[0].tag
      }
    },
    async regenerateElement() {
      try {
        if (this.isRegenerating) return

        this.isRegenerating = true

        const path = this.$children[1]['.path']
        const [pageRef, ] = path.split('vdom');
        const elementPath = this.$children[1].dc.path

        const intent = this.site.intent[0]
        const intentGoal = this.site.intentGoal[0]

        const trimmedPageRef = pageRef.trim();

        await sites.generateElementContent({
          intent,
          intentGoal,
          pageRef: trimmedPageRef,
          elementPath
        })

        this.isRegenerating = false
      } catch (error) {
        console.error(error, 'error')
        this.isRegenerating = false
      }
    }
  },
})
</script>
