// @ts-nocheck
import * as actions from '@/js/store/modules/actions'
import * as getters from '@/js/store/modules/getters'
import { CookieHelper } from '@/js/store/services/cookieHelper'
import validator from 'validator'
import { PropType } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import PopupThumbnailUrl from '../../../../components/editor/steps/super/popup-thumbnail-url/PopupThumbnailUrl.vue'
import { PopupInterface } from '../../common/d'
import Categories from '../../editor/steps/super/Categories.vue'
import EnableMarketplace from '../../editor/steps/super/EnableMarketplace.vue'

const PUBLISH = 'publish'
const INSTALLATION = 'installation'
const CONNECT = 'connect'
const PUBLISHED = 'published'

export default {
  name: 'PublishLayout',
  components: {
    Categories,
    EnableMarketplace,
    PopupThumbnailUrl,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    popup: {
      type: Object as PropType<PopupInterface>,
      required: true,
      default: () => {},
    },
  },
  data(): any {
    return {
      loading: false,
      popupSiteProperties: {},
      script: `
      <script>
        (function (w, d, s, o, f, js, fjs) { w[o] = w[o] || function () { (w[o].q =
          w[o].q || []).push(arguments); }; (js = d.createElement(s)), (fjs =
          d.getElementsByTagName(s)[0]); js.id = o; js.src = f; js.async = 1;
          fjs.parentNode.insertBefore(js, fjs); })(window, document, "script", "_cvt",
          "https://cdn.convrrt.com/libs/popup/widget.js");
          _cvt("init", {
            orgID: '${this.popup.orgID}',
            projectID: "${this.popup.projectID}",
            popupID: "${this.popup.slide.popupID}"
          });
      </script>`,
      currentStatus: CONNECT,
      urlConnected: false,
      input: '',
      isError: false,
      connectLoading: false,
      copiedScript: false,
    }
  },
  computed: {
    ...mapState('globalTheme', {
      showGoogleTagManager: ({
        saPopupSettingsConfig = { publish: { showGoogleTagManager: false } },
      }) => saPopupSettingsConfig.publish.showGoogleTagManager,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      popupConnectBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig?.locations.popupLaunchPageComponent.locations
          .connectBtn.props
          ? globalButtonConfig.locations.popupLaunchPageComponent.locations
              .connectBtn.props
          : {},
      popupAddCodeToSiteBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig?.locations.popupLaunchPageComponent.locations
          .addCodeToSiteBtn.props
          ? globalButtonConfig.locations.popupLaunchPageComponent.locations
              .addCodeToSiteBtn.props
          : {},
      popupPublishBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig?.locations.popupLaunchPageComponent.locations
          .publishBtn.props
          ? globalButtonConfig.locations.popupLaunchPageComponent.locations
              .publishBtn.props
          : {},
      publishPageConfig: ({ globalTheme }) =>
        globalTheme.publishPage ? globalTheme.publishPage : {},
      copySiteCodeBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig?.locations.popupLaunchPageComponent.locations
          .copySiteCodeBtn.props
          ? globalButtonConfig.locations.popupLaunchPageComponent.locations
              .copySiteCodeBtn.props
          : {},
      configurePopupBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig?.locations.popupLaunchPageComponent.locations
          .configurePopupBtn.props
          ? globalButtonConfig.locations.popupLaunchPageComponent.locations
              .configurePopupBtn.props
          : {},
    }),
    ...mapGetters({
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    titleStyle(): any {
      return { 'font-family': this.FontFamily.DEFAULT }
    },
    isConnectSite(): boolean {
      return this.currentStatus === CONNECT
    },
    isInstallation(): boolean {
      return this.currentStatus === INSTALLATION
    },
    isPublishing(): boolean {
      return this.currentStatus === PUBLISH
    },
    popUpPublished(): boolean {
      return this.popup.state === PUBLISHED
    },
    popupSiteMarketPlaceEnabled() {
      return this.popup.marketplace || false
    },
    popupSiteAltThumbnailUrl() {
      return this.popup.thumbnail || ''
    },
    thumbnail: {
      get() {
        console.log(this.popup)
        const thumbnail = this.popup.thumbnails?.[0] || this.popup.thumbnail
        return thumbnail
      },
    },
    showThumbnail() {
      return this.config.SHOW_LAUNCH_PAGE_POPUP_THUMBNAIL
    },

    getCookie() {
      return CookieHelper('_pages_session=')
    },
    setPublishButtonDisabled() {
      /**
       * Function to grant/permit a user ability to publish a page
       * Config key: USER_PUBLISH_POPUP_PERMISSION_CHECK: boolean
       * @returns boolean
       * If the configuration permission USER_PUBLISH_POPUP_PERMISSION_CHECK is turned on for an organization.
       * Then only we should obey the value of user_activated set in the cookie.
       */

      //Config is not set or on
      // unfortunately value has a type of string in spanner, so i can only do this typecheck
      if (this.config.USER_PUBLISH_POPUP_PERMISSION_CHECK === 'TRUE') {
        return true
      }

      //Get the cookie value
      const get_user_active_status =
        this.getCookie?.['USER_PUBLISH_POPUP_PERMISSION_CHECK']

      if (get_user_active_status) {
        return true
      }

      return false
    },
  },
  created() {
    this.currentStatus = this.isPublished ? PUBLISH : CONNECT
  },
  mounted() {
    if (this.popUpPublished) {
      this.urlConnected = true
      this.currentStatus = PUBLISH
    }
  },
  methods: {
    ...mapActions({
      publishPopup: actions.SDA_POPUP_PUBLISH,
      updatePopup: actions.POPUP_UPDATE,
      connectPopupURL: actions.SDA_POPUP_CONNECT_URL,
      setSdaPopupSiteMarketplace: actions.SDA_POPUP_SITE_ENABLE_MARKETPLACE,
      setSdaPopupSiteProperties: actions.SDA_POPUP_UPDATE_PROPERTIES,
    }),
    async publishPopupHandle(event) {
      try {
        this.loading = true
        console.log('publishPopupHandle event:', event)
        await this.publishPopup()
      } catch (err) {
        console.error('failed to publish', err)
      }
      this.loading = false
    },
    installationAction() {
      this.currentStatus = PUBLISH
    },
    onCopyToClipboard() {
      const copyText = this.script
      navigator.clipboard.writeText(copyText)
      this.copiedScript = true
    },
    async copyTextToClipboard() {
      // for old browsers support, where this WEB-API is not available
      // a fallback method will be used to copy the text
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard()
        this.copiedScript = true
        return
      }
      // If it is new or compatible browser, following WEB-API will
      // be called to copy the text to clipboard
      await navigator.clipboard.writeText(this.script)
      this.copiedScript = true
    },
    fallbackCopyTextToClipboard() {
      window.getSelection().removeAllRanges() // remove any previous selections
      let range = document.createRange() // create new Range object
      range.selectNode(this.$refs.scriptCodeElement) // set our Range to contain the Node we want to copy from
      window.getSelection().addRange(range) // select
      document.execCommand('copy') // copy to clipboard
      window.getSelection().removeAllRanges() // remove current and any previous selections
    },
    onConfigureRootDomain() {
      this.currentStatus = CONNECT
    },
    onGetPopupCode() {
      this.copiedScript = false
      this.currentStatus = INSTALLATION
    },
    async connectAction(): Promise<void | boolean> {
      try {
        if (!validator.isURL(this.input)) {
          return (this.isError = true)
        }
        this.isError = false
        this.connectLoading = true

        // await request
        let payload = {
          authorizedDomains: [],
        }

        payload.authorizedDomains.push(this.input)
        await this.connectPopupURL(payload)

        this.connectLoading = false
        this.copiedScript = false
        this.urlConnected = true
        this.currentStatus = INSTALLATION
      } catch (err) {
        this.connectLoading = false
      }
    },
    onChangeUrlInput(e: any) {
      this.isError = false
      this.input = e.target.value
    },
    popupSiteMarketplaceFlagChanged(payload: { value: Boolean }) {
      this.popupSiteProperties.marketplace = payload.value
    },
    popupSiteThumbnailUrlChange(event: any) {
      let thumbnailUrl = event.target.value?.trim() || ''
      this.popupSiteProperties.thumbnail = thumbnailUrl
    },
    submitAdminTabSiteData(event: any) {
      this.popupSiteProperties.marketplace =
        this.popupSiteProperties.marketplace ?? this.popupSiteMarketPlaceEnabled

      this.setSdaPopupSiteProperties({
        popupID: this.popup.id,
        popupSiteProperties: this.popupSiteProperties,
      })
    },
    async nodesIsValid() {
      return true
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => setTimeout(vm.$store.dispatch('loader/stop', ''), 500))
  },
}
