
<div class="">
  <div v-if="!disableAltText" class="row">
    <div class="col text-capitalize">
      {{ $t('element.property.image.alt.label') }}
    </div>
    <div class="col-12">
      <cvt-input
        v-model="alt"
        type="text"
        :placeholder="$t('element.property.image.alt.alt_message')"
      />
    </div>
  </div>

  <div v-if="!disableAltText" class="divider" />

  <div class="row" data-identity="normalBtnActionsDropdownWrap">
    <div class="col-md-12">
      <cvt-select
        mode="dropdown"
        :value="action"
        label="Image Click Action"
        :theme="mode"
        :background-mode="mode"
        @input="(value) => (action = value)"
      >
        <option
          v-for="optionAction in actions"
          :key="optionAction.value"
          :value="optionAction.value"
          v-text="optionAction.label"
        ></option>
      </cvt-select>
    </div>
  </div>

  <div v-if="!disableAltText" class="divider" />

  <div v-if="linkActive" class="row mt-2">
    <div v-if="OrgName !== orgNameFreshWork" class="col text-capitalize">
      {{ $t('element.property.link') }}
    </div>
    <div v-else class="col align-items-center">
      <h6 :class="textColorClass">Hyperlink image</h6>
    </div>
    <div class="col-12">
      <url-input
        v-model="href"
        type="text"
        :placeholder="$t('element.property.image.alt.link_message')"
      />
    </div>
  </div>

  <div v-if="anchorActive" class="row no-gutters">
    <div class="col">
      <h6 :class="textColorClass">Anchor To</h6>
    </div>
    <div class="col-md-12">
      <section-list v-model="anchor" :value="anchor" />
    </div>
  </div>

  <div
    v-if="popupActive && isGridBuilder"
    class="row no-gutters"
    data-identity="normalBtnPopupsDropdownWrap"
  >
    <div class="col-md-12">
      <popup-list-alt
        :key="selected.vnodeId"
        :value="popup"
        @configure-popup="configurePopup"
        @delete-popup="removePopup"
        @change="(value) => (popup = value)"
      />
    </div>

    <div class="col-md-12 justify-content-center">
      <cvt-button
        class="m-1"
        :outlined="true"
        :text="$t('e.popup.create.a.popup')"
        :full-width="true"
        @click="createNewPopup"
      />
    </div>
  </div>
</div>
