
<div data-identity="EnableDemoTemplate">
  <h4 class="admin-label">{{$t('launch.tab.control.heading.enable_demo_template')}}</h4>
  <cvt-switch
    size="sm"
    color="active"
    type="circular"
    description-type="default"
    :initial-state="demoTemplateEnabled"
    :mode="mode"
    @switchToggle="(status) => demoTemplateChange(status)"
  />
</div>
