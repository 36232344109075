<script>
import FirebaseNodeV3 from '../../base/FirebaseNode'
import DraftFroala from "../../../editor/nodes/DraftFroala.vue";
import {FroalaContentChangeCommand} from "@/js/commands/PropertyChangeCommand";

export default DraftFroala.extend({
  name: "DraftFroalaV3",
  mixins: [FirebaseNodeV3],
  methods: {
    saveAction (htmlStr) {
      return this.validateAndPushCommand(
          new FroalaContentChangeCommand(this.getVNodeID(), this.getDataPropsKey('contentsHTML'), htmlStr)
      )
    },
  }
})
</script>
