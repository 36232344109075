<template>
  <div>
    <div
      v-for="(popup, idx) in popupListData"
      :key="idx"
      class="e-popup-list-item d-flex justify-content-between align-items-center"
      :class="activePopupIdx === idx ? 'popup-item-active' : ''"
      @click="() => onClickPopup(popup, idx)"
    >
      <input
        v-if="activePopupIdx === idx"
        :value="fmtName(popup)"
        type="text"
        maxlength="12"
        class="embedded-popup-input"
        @change="(e) => debounceUpdatePopup(e, popup)"
      >
      <span v-else>{{ fmtName(popup) }}</span>
      <div v-if="activePopupIdx === idx">
        <span class="e-popup-icon" @click.stop="onConfigureClicked">
          <i class="cvt-icon-pencil"></i>
        </span>
        <span class="e-popup-icon" @click.stop="() => onDeleteClicked(popup)">
          <i class="fa fa-trash ml-3"></i>
        </span>
      </div>
    </div>

    <div v-if="!popupListData[0]?.popupVNode" class="no-popup-text my-3 text-center">
      {{$t('e.popup.unavailable.message')}}
    </div>
    <div v-if="popupListData[0]?.popupVNode" :class="'divider-with-legend my-2'">or</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import * as _ from 'lodash'
import * as actions from '@/js/store/modules/actions'
import * as getters from "@/js/store/modules/getters";
import {ManualPropertyChangeCommand, PropertyChangeCommand} from "@/js/commands/PropertyChangeCommand";

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupListData: [],
      activePopupIdx: this.value.index,
    }
  },
  computed: {
    ...mapGetters({
      path: getters.DELTA_KEY
    }),
    popupList: {
      get() {
        return this.popupListData
      },
      set(data) {
        this.popupListData = data
      },
    },
    _basePath(){
      return `${this.path}/popup`.replace('/vdom', '')
    }
  },
  created() {
    this.loadPopups()
  },
  methods: {
    ...mapActions({
      popups: actions.POPUP_GET_LIST,
      pushCommand: actions.HISTORY_COMMAND_PUSH,
    }),
    fmtName(popup) {
      return popup.name
    },
    onConfigureClicked() {
      this.$emit('configure-popup')
    },
    onDeleteClicked(popup) {
      this.$emit('delete-popup', popup)
    },
    onClickPopup(popup, idx) {
      this.$emit('change', popup)
      this.activePopupIdx = idx
    },
    debounceUpdatePopup (e, popup) {
      this.updateProps(popup, {key: 'name', value: e.target.value})
    },
    loadPopups () {
      let ret = this.popups()
      console.log(ret, 'ret popupList')
      ret
        .then((popups) => {
          if (popups) {
            let modified = []
            for (let i = 0; i < popups.length; i++) {
              if (popups[i] !== undefined) {
                modified.push({
                  name: popups[i].data.props.name ? popups[i].data.props.name : `popup ${i}`,
                  popupVNode: popups[i].data.props.vnodeId,
                  index: i,
                })
              }
            }
            this.popupList = modified
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async updateProps(popup, data) {
      const command = new ManualPropertyChangeCommand(
          `${this._basePath}/${popup.index}`,
          data.key,
          data.value,
          popup.name
      )
      if (command.hasChanges) {
        await this.pushCommand(command)
        this.loadPopups()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.e-popup-list-item {
  background-color: #F0F2F6;
  padding: 0.6rem 1.2rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
  width: 100%;
}
.no-popup-text {
  font-size: 0.8rem;
}
.e-popup-icon {
  cursor: pointer;
}
.popup-item-active {
  background-color: #DFFAF1;
  border: 1px solid #55BFBD;
}

.embedded-popup-input {
  width: 60%;
  outline: none;
  border: 0;
  background-color: #DFFAF1;
}
</style>
