
<div>
  <cvt-button
      :outlined="true"
      :full-width="true"
      shape="rounded"
      color = "light"
      :mode="mode"
      :text="`${genAI.label || 'Assign A Variable'}`"
      @click="openGenAIDialog"
  />
  <div v-if="genAI.label" class="row no-gutters" >
    <div class="col-md-12">
      <cvt-input
          v-model="description"
          type="text"
          label="Description"
          :clearable="false"
      />
    </div>

    <div class="col-md-12 mt-3">
      <cvt-input
          v-model="maxLength"
          type="number"
          label="Max Length"
          :clearable="false"
      />
    </div>
  </div>
</div>
