import * as bb from 'bluebird'
import {
  SearchPopupsArgs,
  UpdatePopupPropertiesArgs,
} from '../../common/types/api/v1/PopupSite'
import ApiClient from './ApiClient'

export class Auth extends ApiClient {
  currentUser() {
    const dt = new Date()
    const ts = dt.getTime()
    return this.get(`/auth/currentUser?ts=${ts}`)
  }
}

export class CustomDomains extends ApiClient {
  loadDomains({ siteId }) {
    return this.post(`/v1/sites/custom-domains/load-domains`, { siteId })
  }

  connectDomain({ siteId, domain }) {
    return this.post(`/v1/sites/${siteId}/domains`, { siteId, domain })
  }

  disconnectDomain({ siteId, domain }) {
    return this.delete(`/v1/sites/${siteId}/domains/${domain}`)
  }
}

export class Marketplace extends ApiClient {
  templates(params) {
    return this.get('/v1/marketplace/templates', params)
  }

  templateByID(id) {
    return this.get(`/v1/marketplace/templates/${id}`)
  }

  countTemplates(params) {
    return this.get('/v1/marketplace/templates/count', params)
  }

  saveGenAiTemplates(siteId: string, templateData: {
    intent: Array<string>,
    intentGoal: Array<string>,
    data?: {[key: string]: {}}
  }) {
    return this.post(`/v1/marketplace/${siteId}/enable-genai`, templateData)
  }

  genAiTemplates(params: {
    intent: Array<string>,
    intentGoal: Array<string>
  }) {
    return this.post('/v1/marketplace/gen-ai/templates', params).then(({ data }) => data)
  }
}

export class PopUpMarketplace extends ApiClient {
  popupTemplates(params) {
    return this.get('/v1/popup/marketplace/templates', params)
  }

  popupByID(id: string) {
    return this.get(`/v1/popup/marketplace/templates/${id}`)
  }

  clonePopupFromMarketplaceTemplate(popUpTemplateId: string, foreignData: any) {
    return this.post(`/v1/popup/marketplace/${popUpTemplateId}/clone`, {
      foreignData,
    })
  }

  countPopupTemplate(params) {
    return this.get('/v1/popup/marketplace/templates/count', params)
  }
}

export class Organization extends ApiClient {
  addOrganization(data) {
    return this.post(`/super/create/addOrganization`, data).then(
      ({ data }) => data,
    )
  }

  enterpriseOnboarding(data) {
    return this.post(`/v1/organization/admin/onboard`, data)
  }

  upsertFeatureConfig(data) {
    return this.post(`/v1/organization/admin/config`, data)
  }

  fetchConfig() {
    return this.get(`/config`)
  }

  fetchBrand(domain: string) {
    return this.get(`/v1/organization/admin/brandFetch?domain=${domain}`)
  }

  inviteITMember(data: { email: string; role: string }[]) {
    return this.post(`/v1/organization/admin/members`, data)
  }

  deleteITMember(userId: string) {
    return this.delete(`/v1/organization/admin/members`, userId)
  }

  resendITInvite(userId: string) {
    return this.post(`/v1/organization/admin/members/${userId}/resend-invite`)
  }

  fetchITMember() {
    return this.get(`/v1/organization/admin/members`)
  }

  fetchEnterprise() {
    return this.get(`/v1/organization/fetch-enterprise`)
  }

  loadCustomeHostNames() {
    return this.get(`/v1/organization/admin/custom-hostnames`)
  }

  fetchEnvJwtToken() {
    return this.get(`/v1/organization/admin/get-env-jwt`)
  }

  fetchDnsRecord() {
    return this.get(`/v1/organization/admin/customer-domains`)
  }

  createDnsRecord(data: { production: string; staging: string; cdn: string }) {
    return this.post(`/v1/organization/admin/customer-domains`, data)
  }

  verifyCustomHostnames() {
    return this.get(`/v1/organization/admin/customer-domains/verify`)
  }

  addOIDCConfig(data) {
    return this.post(`/v1/organization/admin/add-oidc-config`, data)
  }

  fetctOIDCConfig(data) {
    return this.get(`/v1/organization/admin/oidc-config`, data)
  }

  createIODCconfig(data) {
    return this.post(`/v1/organization/admin/create-oidc-config`, data)
  }

  updateChatWidgetConfiguration(payload: object) {
    return this.put(`/v1/projects/chat-widget-config`, payload)
  }

  sendMFACode(data: { to: string; channel: string; redirectURL: string }) {
    return this.post(`/auth/mfa/send`, data)
  }

  verifyMFACode(data: { to: string; code: string; xhr: boolean }) {
    return this.post(`/auth/mfa/verify`, data)
  }

  fetchDemoTemplates() {
    return this.get(`/v1/organization/demo-templates`)
  }
}

export class Super extends ApiClient {
  searchOrganizations({ orgID }) {
    return this.get('/super/search/organizations', { params: { orgID } })
  }

  searchProjects({ orgID, projectID }) {
    return this.get(`/super/search/organizations/${orgID}/projects`, {
      projectID,
    })
  }

  sudo({ orgID, projectID }) {
    return this.post('/super/sudo', { orgID, projectID })
  }
}

export class V1CRM extends ApiClient {
  formFields(params) {
    return this.get('/v1/crm/form/fields', params)
  }

  createFormField(field) {
    return this.post('/v1/crm/form/fields', field)
  }

  createList({ name }) {
    return this.post('/v1/crm/lists', { name })
  }

  getTags(query) {
    return this.get('/v1/crm/tags', { query })
  }

  createTag({ name, description }) {
    return this.post('/v1/crm/tags', { name, description })
  }

  searchFiles({ name, viewable, type }) {
    return this.get('/v1/crm/files', { name, viewable, type })
  }

  createNewGoogleSheet({ title }: { title: string }) {
    return this.post('/v1/integrations/googleSheet/create', { title })
  }

  createCrmList(field) {
    return this.post('/v1/crm/lists', field)
  }
}

export class V1ECommerce extends ApiClient {
  async listProducts(params) {
    const { data: products } = await this.get(
      '/v1/crm/e-commerce/products',
      params,
    )
    return products
  }

  async searchProducts(params) {
    const { data: products } = await this.get(
      '/v1/crm/e-commerce/products/search',
      params,
    )
    return products
  }

  async searchProductsWithSkus(params) {
    return bb.map(await this.searchProducts(params), async (product) => {
      product.skus = await this.listSkusByProductId(product.id, params)
      return product
    })
  }

  async listSkusByProductId(productId, params) {
    const { data: skus } = await this.get(
      `/v1/crm/e-commerce/products/${productId}/skus`,
      params,
    )
    return skus
  }

  async listPlansByProductId(productId, params) {
    const { data: plans } = await this.get(
      `/v1/crm/e-commerce/products/${productId}/plans`,
      params,
    )
    return plans
  }

  async deleteProductById(productId) {
    const { data: product } = await this.delete(
      `/v1/crm/e-commerce/products/${productId}`,
    )
    return product
  }

  async createProduct(options) {
    const { data: product } = await this.post(
      '/v1/crm/e-commerce/products',
      options,
    )
    return product
  }

  async createProductPlan(productId, options) {
    const { data: plan } = await this.post(
      `/v1/crm/e-commerce/products/${productId}/plans`,
      options,
    )
    return plan
  }
}

export class V1Sites extends ApiClient {
  all({
    market,
    archived,
    limit,
    offset,
    dateCreated,
    dateUpdated,
    isPublished,
    search,
    searchId,
  }) {
    return this.get('/v1/sites', {
      market,
      archived,
      limit,
      offset,
      dateCreated,
      dateUpdated,
      isPublished,
      search,
      searchId,
    })
  }

  byId(siteId) {
    return this.get(`/v1/sites/${siteId}`)
  }

  updateById(siteId, site) {
    return this.put(`/v1/sites/${siteId}`, site)
  }

  create(foreignData) {
    return this.post('/v1/sites', { foreignData })
  }

  publish(siteId) {
    return this.post(`/v1/sites/${siteId}/publish`)
  }

  unpublish(siteId) {
    return this.post(`/v1/sites/${siteId}/unpublish`)
  }

  fromTemplate(id, foreignData) {
    return this.post(`/v1/sites/fromTemplate/${id}`, { foreignData })
  }

  fromGenAiTemplate(siteId: string, data: {
    intent: string[],
    intentGoal: string[],
    businessDescription: string,
    businessSummary: string[]
  }) {
    return this.post(`/v1/sites/fromGenAiTemplate/${siteId}`, data).then(({ data }) => data)
  }

  generateSiteContent(body: {
    intent: string,
    intentGoal: string,
    pageRef: string
  }) {
    return this.post(`/v1/sites/generate-site-content`, body).then(({ data }) => data)
  }

  generateElementContent(params: {
    intent: string,
    intentGoal: string,
    pageRef: string,
    elementPath: string
  }) {
    return this.post(`/v1/sites/generate-element-content`, params).then(({ data }) => data)
  }

  updateThumbnails(id) {
    return this.post(`/v1/sites/${id}/updateThumbnails`)
  }

  getAllCategories() {
    return this.get(`/v1/sites/categories`)
  }

  getCategories(id) {
    return this.get(`/v1/sites/super/${id}/categories`)
  }

  updateCategories(id, categories) {
    return this.put(`/v1/sites/super/${id}/categories`, categories)
  }

  updateSiteProperties(id, data) {
    return this.put(`/v1/sites/super/${id}/marketplace`, { ...data })
  }

  updateCampaignNode(id, campaignData = null) {
    if (campaignData === null) {
      console.debug('no campaign data to update a node with -- skipping')
      return Promise.resolve()
    }
    return this.post(`/v1/sites/${id}/updateCampaignNode`, campaignData)
  }

  analytics({ siteId, window }) {
    return this.get(`/v1/sites/${siteId}/analytics`, { window })
  }

  regenerateForms({ siteId }) {
    return this.post(`/v1/sites/${siteId}/regenerateForms`)
  }

  archive(siteId) {
    return this.delete(`/v1/sites/${siteId}`)
  }

  restore(siteId) {
    return this.get(`/v1/sites/${siteId}/restore`)
  }

  updatePage(page) {
    const { siteId, id } = page
    return this.put(`/v1/sites/${siteId}/pages/${id}`, page)
  }

  clonePage({ siteId, id }) {
    return this.post(`/v1/sites/${siteId}/pages/${id}/clone`)
  }

  insertPage({ siteId, pageProps }) {
    return this.post(`/v1/sites/${siteId}/insertPage`, pageProps)
  }

  savePage({ siteId, id, pageProps }) {
    return this.post(`/v1/sites/${siteId}/pages/${id}/save`, pageProps)
  }

  deletePage({ siteId, id }) {
    return this.delete(`/v1/sites/${siteId}/pages/${id}`)
  }

  replacePage({ siteId, id, pageProps }) {
    return this.post(`/v1/sites/${siteId}/pages/${id}/replacePage`, pageProps)
  }

  createPage(page) {
    const { siteId } = page
    return this.post(`/v1/sites/${siteId}/pages`, page)
  }

  switchBuilderVersion(payload) {
    return this.post(`/v1/organization/project/gridVersion`, payload)
  }

  savePreference(data: { name: string }, siteId: string) {
    return this.post(`/v1/sites/${siteId}/preferences`, data)
  }

  updatePreference(data: { name: string }, siteId: string) {
    return this.put(`/v1/sites/${siteId}/preferences`, data)
  }

  fetchPreferences() {
    return this.get(`/v1/sites/preferences`)
  }
}

export class V1PopupSite extends ApiClient {
  all(searchPopupParams: SearchPopupsArgs) {
    return this.get('/v1/popup/search', searchPopupParams)
  }

  byId(popUpId: string) {
    return this.get(`/v1/popup/${popUpId}`)
  }

  updateById(popUpId: string, popup: any) {
    return this.put(`/v1/popup/${popUpId}`, popup)
  }

  create(foreignData: any) {
    return this.post('/v1/popup', { foreignData })
  }

  publish(popUpId: string) {
    return this.post(`/v1/popup/${popUpId}/publish`)
  }

  unpublish(popUpId: string) {
    return this.post(`/v1/popup/${popUpId}/unpublish`)
  }

  fromPopupSite(popUpId: string) {
    return this.post(`/v1/popup/${popUpId}/clone`)
  }

  updateThumbnails(popUpId: string) {
    return this.post(`/v1/popup/${popUpId}/updateThumbnails`)
  }

  archive(popUpId: string) {
    return this.delete(`/v1/popup/${popUpId}`)
  }

  restore(popUpId: string) {
    return this.get(`/v1/popup/${popUpId}/restore`)
  }

  enablePopupMarketplace(id: string, enabled: boolean) {
    return this.put(`/v1/popup/super/${id}/marketplace`, { enabled })
  }

  updateCategories(id: string, categories) {
    return this.put(`/v1/popup/super/${id}/categories`, categories)
  }

  connect_url(popupID: string, url: string) {
    return this.post(`/v1/popup/${popupID}/connect-url`, url)
  }

  updateProperties(
    popupID: string,
    updatePopupProperties: UpdatePopupPropertiesArgs,
  ) {
    return this.put(
      `/v1/popup/super/${popupID}/properties`,
      updatePopupProperties,
    )
  }
}

export class V1Stripe extends ApiClient {
  connect({ code }) {
    return this.post('/v1/stripe/connect', { code }).then(({ data }) => data)
  }

  account() {
    return this.get('v1/stripe/api/account').then(({ data }) => data)
  }

  getAllProducts({ limit, shippable = false }) {
    return this.get('/v1/stripe/api/products', {
      params: { limit, shippable },
    }).then(({ data }) => data)
  }

  createProduct(product) {
    return this.post('/v1/stripe/api/products', product).then(
      ({ data }) => data,
    )
  }

  createProductSKU(productId, sku) {
    return this.post(`/v1/stripe/api/products/${productId}/skus`, sku).then(
      ({ data }) => data,
    )
  }

  updateProduct(productId, data) {
    return this.post(`/v1/stripe/api/products/${productId}`, data).then(
      ({ data }) => data,
    )
  }

  deleteProduct(productId) {
    return this.delete(`/v1/stripe/api/products/${productId}`).then(
      ({ data }) => data,
    )
  }
}

export class V1DynamicContent extends ApiClient {
  async preview(params: {
    excludeIds?: string[]
    previewId?: string
    filter: { intent: string; intentGoal: string }
  }) {
    const { data: content } = await this.post('/v1/preview', params, {
      timeout: 60000,
    })
    return content as {
      excludeIds: string[]
      previewId: string
      templateId: string
      previewUrl: string
    }
  }

  async selectTemplate(params: { previewId: string }) {
    const { data: content } = await this.post('/v1/preview/template/', params)
    return content
  }

  getPreviewURL(previewUrl: string, mobile: boolean = false) {
    let url = previewUrl
    if (mobile) {
      if (previewUrl.search('/?/')) {
        url += '&mobile=true'
      } else {
        url += '?mobile=true'
      }
    }
    return url
  }

  getPreviewURLAlt(previewUrl: string, mobile: boolean = false) {
    const url = new URL(previewUrl);
    mobile ? url.searchParams.set('mobile', 'true') : url.searchParams.delete('mobile');

    return url.toString();
  }
}

export default new ApiClient()
